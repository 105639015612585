import {
    IGatsbyImageData,
    getImage
} from 'gatsby-plugin-image';

import {
    GatsbyStrapiPictureField,
    StrapiPictureField
} from '../models/cms-common';

export const buildImageUrl = (picture: StrapiPictureField) => {
    if (Array.isArray(picture)) {
        return `${process.env.REACT_APP_CMS_URL}${picture[0].url}`;
    } else {
        return `${process.env.REACT_APP_CMS_URL}${picture.url}`;
    }
};

export const buildGatsbyStrapiImage = (field: GatsbyStrapiPictureField | undefined) => {
    if (typeof field === 'undefined' || field === null) {
        return undefined;
    }

    let childImageSharp = null;
    if (Array.isArray(field)) {
        childImageSharp = field[0]?.localFile?.childImageSharp;
    } else {
        childImageSharp = field?.localFile?.childImageSharp;
    }
    
    return childImageSharp ? getImage(childImageSharp.gatsbyImageData) : undefined;
};

export const buildGatsbyStrapiImageArray = (field: GatsbyStrapiPictureField): IGatsbyImageData[] => {
    if (field === null) {
        return [];
    }

    if (Array.isArray(field)) {
        const newArray: IGatsbyImageData[] = [];
        field.forEach(elem => {
            const img = getImage(elem.localFile.childImageSharp.gatsbyImageData);
            if (img) {
                newArray.push(img);
            }
        });
        return newArray;
    }
    return [];
};