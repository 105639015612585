import * as React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import {
    SimpleImageBlockProps,
    aboutUsDetailsSubDomainProp
} from '../../../models/cms-common';
import { buildGatsbyStrapiImage } from '../../../utils/cms-utils';
import { isUndOrEmpty } from '../../../utils/string-utils';
import * as styles from './about-us-details-block.module.css';

interface AboutUsDetailsBlockProps {
    id?: string;
    aboutUsDetails: (SimpleImageBlockProps & {
        accentColor?: string;
    })[];
    aboutUsDetailsSubDomains: aboutUsDetailsSubDomainProp[];
}

export const AboutUsDetailsBlock: React.FC<AboutUsDetailsBlockProps> = ({
    id,
    aboutUsDetails,
    aboutUsDetailsSubDomains
}: AboutUsDetailsBlockProps) => {
    return (
        <React.Fragment>
            <div className={'container my-5'}>
                <div id={id} className='d-flex justify-content-center'>
                    <div className="row col-12 pl-0">
                        {aboutUsDetails.map((int, index) => {
                            const img = buildGatsbyStrapiImage(int.picture);
                            const isFull = aboutUsDetails.length % 2 !== 0 && index == aboutUsDetails.length - 1;

                            const subDomains = aboutUsDetailsSubDomains.filter(sd => sd.title === int.title);
                            let expertises: any[] = [];

                            if (subDomains && subDomains.length &&
                                subDomains[0].expertises && subDomains[0].expertises.length) {
                                expertises = subDomains[0].expertises;
                            }

                            return (<div key={`${int.title}-${index}`}
                                className={`${isFull ? 'col-12' : 'col-12 col-lg-6'} ml-0 pl-0 pl-lg-3 my-3`}>
                                <div className={`w-100 h-100 px-5 pt-5 m-3 ${styles.intItem}`}>
                                    <div className='d-flex flex-wrap-reverse align-items-stretch'>
                                        <div
                                            className={
                                                `col-12 col-lg-9 align-self-center 
                                                text-center text-lg-left 
                                                px-3 py-3 py-lg-1
                                                ${styles.intTitle}`
                                            }
                                        >
                                            {!isUndOrEmpty(int.title) && (
                                                <h3>{int.title}</h3>
                                            )}
                                        </div>
                                        <div className='col-12 col-lg-3 align-items-center justify-content-end'>
                                            <div className='
                                                d-flex align-self-center justify-content-center justify-content-lg-end
                                            '>
                                                {img && (
                                                    <GatsbyImage
                                                        image={img}
                                                        objectFit='contain'
                                                        alt=""
                                                    />
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        className="mb-5 px-3 col-12 col-lg-9 text-center text-lg-left"
                                        style={{ lineHeight: '22px' }}>
                                        {!isUndOrEmpty(int.description) &&
                                            <ReactMarkdown>{int.description}</ReactMarkdown>}
                                    </div>
                                    {
                                        (expertises && expertises.length > 0) && <>
                                            <div className="row justify-content-around">
                                                {
                                                    expertises.map((expertise: any, index: number) => {
                                                        const expertiseImg = buildGatsbyStrapiImage(expertise.picture);
                                                        return (
                                                            <div key={`${expertise.title}-${index}`}
                                                                className={`col-12 col-lg-2
                                                                            d-flex justify-content-center`}>
                                                                <div>
                                                                    <div className={`col-12 
                                                                            d-flex justify-content-center`}>
                                                                        <div
                                                                            className={
                                                                                `d-flex align-items-center 
                                                                                ${styles.expertiseImgContainer}`
                                                                            }>
                                                                            {expertiseImg && (
                                                                                <GatsbyImage
                                                                                    className={styles.expertiseImg}
                                                                                    image={expertiseImg}
                                                                                    objectFit='contain'
                                                                                    alt=""
                                                                                />
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                    <div className="my-3 text-center">
                                                                        {!isUndOrEmpty(expertise.title) && (
                                                                            <span className="text-center">
                                                                                {expertise.title}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};