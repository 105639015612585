import * as React from 'react';

import {
    StaticQuery,
    graphql
} from 'gatsby';

import { AboutUsDetailsBlock }
    from '../../components/about-us-page/about-us-details-block/about-us-details-block.component';
import { OurValuesBlock } from '../../components/about-us-page/our-values-block/our-values-block.component';
import { Head as DefaultHead } from '../../components/head/head.component';
import { CustomSection } from '../../components/shared/custom-section/custom-section.component';
import { PageTopBlock } from '../../components/shared/page-top-block/page-top-block.component';
import { seo } from '../../config/seo-config';
import {
    BannerProps,
    SimpleImageBlockProps,
    SimpleTextBlockProps,
    aboutUsDetailsSubDomainProp
} from '../../models/cms-common';
import { isBrowser } from '../../utils/url-utils';
import * as styles from './index.module.css';

interface NousConnaitreProps {
    // Banner
    banner: BannerProps

    // AboutUsDetails
    aboutUsDetails: (SimpleImageBlockProps & {
        accentColor?: string;
    })[];

    aboutUsDetailsSubDomains: aboutUsDetailsSubDomainProp[];

    // NosValeurs
    nosValeursTitle: string;

    // -- Block 1
    nosValeursTextBlock1: SimpleTextBlockProps;

    // -- Block 2
    nosValeursTextBlock2: SimpleTextBlockProps;

    ref: React.RefObject<HTMLDivElement>;
}

const aboutUsPage: React.FC<NousConnaitreProps> = ({
    // Banner
    banner,

    // AboutUsDetails
    aboutUsDetails,

    aboutUsDetailsSubDomains,

    // NosValeurs
    nosValeursTitle,

    // -- Block 1
    nosValeursTextBlock1,

    // -- Block 2
    nosValeursTextBlock2,

    ref
}: NousConnaitreProps) => {

    const [transparentMode, setTransparentMode] = React.useState(
        isBrowser() ? document.body.scrollTop < Math.round(window.innerHeight / 2) : false
    );
    const [currentBgColor, setCurrentBgColor] = React.useState<string>('var(--primary-blue)');
    const [darkMode, setDarkMode] = React.useState(true);

    return (
        <>
            <PageTopBlock
                ref={ref}
                transparent={transparentMode} dark={darkMode} backgroundColor={currentBgColor}
                setCurrentBgColor={setCurrentBgColor} setTransparentMode={setTransparentMode} setDarkMode={setDarkMode}

                banner={banner}
                scrollIntoElementId={'about-us-details'}
                contentBlockAElementId={'about-us-details'}
                contentBlockBElementId={'nos-valeurs'}
                bannerBackgroundClassName={'AboutUs'}>

                <CustomSection
                    className={`d-flex align-items-center ${styles.blockA}`}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('var(--white)');
                    }}
                    viewport={{ amount: 0.5 }}
                >
                    <AboutUsDetailsBlock
                        id='about-us-details'
                        aboutUsDetails={aboutUsDetails}
                        aboutUsDetailsSubDomains={aboutUsDetailsSubDomains}
                    />
                </CustomSection>

                <CustomSection
                    className={`d-flex align-items-center ${styles.blockB}`}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('var(--white)');
                    }}
                    viewport={{ amount: 0.5 }}
                >
                    <OurValuesBlock
                        id='nos-valeurs'
                        title={nosValeursTitle}
                        textBlock1={nosValeursTextBlock1}
                        textBlock2={nosValeursTextBlock2}
                    />
                </CustomSection>

            </PageTopBlock>
        </>
    );
};

const NousConnaitreEnhancer = (Component: React.ComponentType<NousConnaitreProps>) => {
    return (
        <StaticQuery<{ strapiAboutUs: NousConnaitreProps }>
            query={graphql`
				query AboutUsQuery {
					strapiAboutUs {
                        banner {
                            title
                            subtitle
                        }

                        aboutUsDetails {
							picture {
								localFile {
									childImageSharp {
										gatsbyImageData
								  	}
								}
							}
							title
							description
						}

                        aboutUsDetailsSubDomains {
							title
                            expertises {
                                title
                                description
                                picture {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData
                                        }
                                    }
                                }
                            }
						}
						
						nosValeursTitle
						nosValeursTextBlock1 {
                            title
                            subtitle
                            description
                        }
                        nosValeursTextBlock2 {
                            title
                            subtitle
                            description
                        }
					}
				}
				`
            }
            render={data => <Component {...data.strapiAboutUs} />}
        />
    );
};

function NousConnaitrePage() {
    return NousConnaitreEnhancer(aboutUsPage);
}

export default NousConnaitrePage;

export const Head = () => <DefaultHead {...seo.nousConnaitre} />;