import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { SimpleTextBlockProps } from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { TitleBlock } from '../../shared/title-block/title-block.component';
import * as styles from './our-values-block.module.css';

interface OurValuesBlockProps {
    id?: string;
    title?: string;
    textBlock1?: SimpleTextBlockProps;
    textBlock2?: SimpleTextBlockProps;
}

export const OurValuesBlock: React.FC<OurValuesBlockProps> = ({
    id,
    title,
    textBlock1,
    textBlock2,
}: OurValuesBlockProps) => {
    return (
        <React.Fragment>
            <div id={id} className="container">
                {!isUndOrEmpty(title) && (
                    <TitleBlock
                        rootClassName="justify-content-center justify-content-lg-start pt-5"
                        withGreenCircle={true}
                    >
                        {title}
                    </TitleBlock>
                )}

                <div className="py-5">
                    <div className='d-flex flex-column flex-lg-row-reverse'>
                        <div className="col-12 col-lg-6">
                            <StaticImage
                                className={styles.NosValeursImg}
                                src="../../../assets/images/nos_valeurs_1.png"
                                alt={textBlock1 && textBlock1.title ? textBlock1.title : ''}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className={styles.skillTxtContainer}>
                                {textBlock1 && (
                                    <>
                                        {!isUndOrEmpty(textBlock1.title) && (
                                            <div className={styles.skillItemTitle}>
                                                <h3>{`« ${textBlock1.title} »`}</h3>
                                            </div>
                                        )}
                                        {!isUndOrEmpty(textBlock1.description) && (
                                            <div className="text-left">
                                                <ReactMarkdown>{textBlock1.description}</ReactMarkdown>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='mt-5 d-flex flex-column flex-lg-row'>
                        <div className="col-12 col-lg-6">
                            <StaticImage
                                className={styles.NosValeursImg}
                                src="../../../assets/images/nos_valeurs_2.png"
                                alt={textBlock1 && textBlock1.title ? textBlock1.title : ''}
                            />
                        </div>
                        <div className="col-12 col-lg-6 ">
                            <div className={styles.skillTxtContainer}>
                                {textBlock2 && (
                                    <>
                                        {!isUndOrEmpty(textBlock2.title) && (
                                            <div className={styles.skillItemTitle}>
                                                <h3>{`« ${textBlock2.title} »`}</h3>
                                            </div>
                                        )}
                                        {!isUndOrEmpty(textBlock2.description) && (
                                            <div className="text-left">
                                                <ReactMarkdown>{textBlock2.description}</ReactMarkdown>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
