// extracted by mini-css-extract-plugin
export var expertiseImg = "about-us-details-block-module--expertiseImg--21981";
export var expertiseImgContainer = "about-us-details-block-module--expertiseImgContainer--2d0f2";
export var intImg = "about-us-details-block-module--intImg--183f3";
export var intImgContainer = "about-us-details-block-module--intImgContainer--c6ddb";
export var intItem = "about-us-details-block-module--intItem--cf232";
export var intItemTitle = "about-us-details-block-module--intItemTitle--89b25";
export var intItemUpper = "about-us-details-block-module--intItemUpper--ba149";
export var intSep = "about-us-details-block-module--intSep--46e57";
export var intTitle = "about-us-details-block-module--intTitle--2e0cf";
export var intTxtContainer = "about-us-details-block-module--intTxtContainer--4e6ef";